<script>
import { useServices } from '/~/extensions/health/composables'
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'hlth-tile-service',
  components: {
    ButtonIcon,
    BaseImage,
  },
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isBookmarked, toggleBookmark } = useServices()

    return {
      isBookmarked,
      toggleBookmark,
    }
  },
}
</script>

<template>
  <div
    class="flex cursor-pointer overflow-hidden rounded-lg bg-white shadow-sm"
    @click="$emit('tile-click', tile)"
  >
    <div class="w-[140px] flex-none">
      <base-image
        :src="tile.image"
        :rounded="false"
        :ratio="0.81"
        class="h-full"
      />
    </div>
    <div
      class="z-1 -ml-5 mt-[15px] flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full bg-white p-[5px] text-primary shadow-sm hover:shadow"
    >
      <button-icon
        :icon="
          isBookmarked(tile.objectID)
            ? 'plain/bookmark-marked'
            : 'plain/bookmark'
        "
        size="md"
        @click.stop="toggleBookmark(tile.objectID)"
      />
    </div>
    <div class="flex flex-col overflow-hidden p-[15px]">
      <h3 class="text-base font-bold">
        {{ tile.title }}
      </h3>

      <p
        class="mt-[5px] break-words border-b pb-[15px] text-sm font-bold text-eonx-neutral-600"
      >
        {{ (tile.location || '').replace(/\/$/, '') }}
      </p>
      <div class="mt-2.5 line-clamp-2 text-sm text-eonx-neutral-800">
        {{ tile.description }}
      </div>
    </div>
  </div>
</template>
